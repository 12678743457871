<script>
import LoadingVue from '@/components/ui/Loading.vue'
import sanitizer from '@/util/htmlSanitizer'

import { getTechHandover } from '@/api/platform/community.js'

import LanguageConverter from '@/util/LanguageConverter'

export default {
  data() {
    return {
      pageId: this.$route.params.id,
      listUrl: this.$route.path.slice(0, this.$route.path.lastIndexOf('/')),
      isLoading: false,
      loadedData: null
    }
  },
  components: { LoadingVue },
  methods: {
    sanitizer,
    LanguageConverter,
    numberFormat(num) {
      return new Intl.NumberFormat().format(num)
    },
    async getData() {
      this.isLoading = true
      const data = await getTechHandover(this.pageId)

      if (data.techTransferYear) {
        data.techTransferYear = this.$getDate(data.techTransferYear).substr(0, 4)
      }

      this.isLoading = false
      return data
    },
    goList() {
      this.$router.push(this.listUrl)
    }
  },
  async mounted() {
    this.loadedData = await this.getData()
  }
}
</script>

<template>
  <LoadingVue v-if="isLoading" />
  <section class="wf__section" v-if="loadedData">
    <div class="wf__title-wrapper">
      <h2 class="wf__title">기업협력</h2>
      <div class="divider"></div>

      <div class="wf__board-title-wrapper">
        <h3 class="wf__board-title">{{ LanguageConverter({ en: loadedData.techNameEn, kr: loadedData.techName }) }}</h3>

        <router-link class="wf__author" :to="`/lab/${loadedData.labNo}`">{{ loadedData.labName }} 바로가기</router-link>

        <ul class="wf__board-desc">
          <li>{{ $getDate(loadedData.createdAt) }}</li>
          <li>|</li>
          <li>조회 {{ numberFormat(loadedData.techViewCount) }}</li>
        </ul>
      </div>
    </div>

    <div class="adminTable">
      <table>
        <colgroup>
          <col width="10%" />
          <col width="90%" />
        </colgroup>

        <tbody>
          <tr>
            <th>{{ LanguageConverter({ en: 'Apply Field', kr: '적용분야' }) }}</th>
            <td>{{ LanguageConverter({ en: loadedData.techApplyFieldEn, kr: loadedData.techApplyField }) }}</td>
          </tr>
          <tr>
            <th>{{ LanguageConverter({ en: 'Level', kr: '기술 성숙도 단계' }) }}</th>
            <td>{{ loadedData.techGrowLevel.includes('단계') ? loadedData.techGrowLevel : `${loadedData.techGrowLevel}단계` }}</td>
          </tr>
          <tr>
            <th>{{ LanguageConverter({ en: 'Transfer Type', kr: '기업협력 유형' }) }}</th>
            <td>{{ loadedData.techTransferType }}</td>
          </tr>
          <tr>
            <th>{{ LanguageConverter({ en: 'Result', kr: '기업협력 성과' }) }}</th>
            <td>{{ LanguageConverter({ en: loadedData.techTransferResultEn, kr: loadedData.techTransferResult }) }}</td>
          </tr>
          <tr>
            <th>{{ LanguageConverter({ en: 'Summary', kr: '기술개요' }) }}</th>
            <td v-html="sanitizer(LanguageConverter({ en: loadedData.techSummaryEn, kr: loadedData.techSummary }))"></td>
          </tr>
          <tr>
            <th>{{ LanguageConverter({ en: 'FollowUp Support', kr: '후속지원' }) }}</th>
            <td>{{ LanguageConverter({ en: loadedData.techFollowUpSupportEn, kr: loadedData.techFollowUpSupport }) }}</td>
          </tr>
          <tr>
            <th>{{ LanguageConverter({ en: 'Note', kr: '연구실협력특이사항' }) }}</th>
            <td v-html="sanitizer(LanguageConverter({ en: loadedData.techSpecialNoteEn, kr: loadedData.techSpecialNote }))"></td>
          </tr>
          <tr>
            <th>{{ LanguageConverter({ en: 'Status', kr: '상태' }) }}</th>
            <td>{{ LanguageConverter({ en: loadedData.techStatus, kr: loadedData.techStatus }) }}</td>
          </tr>
          <tr>
            <th>{{ LanguageConverter({ en: 'Progress', kr: '진행상황' }) }}</th>
            <td>{{ LanguageConverter({ en: loadedData.techProgress, kr: loadedData.techProgress }) }}</td>
          </tr>
          <tr>
            <th>{{ LanguageConverter({ en: 'Transfered Year', kr: '이전년도' }) }}</th>
            <td>{{ loadedData.techTransferYear }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="divider"></div>

    <div>
      <span class="wf__notice-list-btn" @click="goList">List</span>
    </div>
  </section>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.wf__section {
  margin: 4rem 0;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  font-size: var(--wf-text-16);
}

.wf__title-wrapper {
  text-align: center;
}
.wf__board-title-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;

  font-size: 16px;
  font-weight: 300;
}
.wf__title {
  font-size: var(--wf-text-24);
  font-weight: bold;
  text-align: left;
}
.wf__board-title {
  word-break: normal;
  font-size: 38px;
  font-weight: 700;
  margin-top: 46px;
  max-width: 28em;
}
.wf__board-desc {
  display: flex;
  justify-content: center;
  gap: 0.5em;
}
.wf__author {
  color: var(--wf-primary-color);
  font-weight: 400;
}
.divider {
  height: 2px;
  background-color: var(--wf-gray-color);

  margin: 1rem 0;
}

.adminTable,
.adminTable table {
  min-width: 0;
  width: 100%;
  word-break: normal;
}
.adminTable th {
  text-align: center;
  font-size: 16px;
}
.adminTable td {
  padding: 1em 2em;
  font-size: 16px;
}

.wf__notice-list-btn {
  font-size: 1em;
  letter-spacing: 0.5px;
  font-weight: 400;
  color: #fff;
  background-color: var(--wf-primary-color);
  float: right;
  cursor: pointer;
  border-radius: 0.25em;
  padding: 0.35em 1em;
}
.wf__notice-list-btn:hover {
  opacity: 0.75;
}
</style>
